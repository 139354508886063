import "./style/normalize.css";
import "./style/layout.css";
import "./style/colors.css";
import "./style/typography.css";
import App from "./components/app.tsx";
import { createStore, Provider } from "unistore/full/preact";
import { AWS_REGIONS, AGENT_COUNT_RANGES, INDUSTRIES } from "./constants";

if (API_MOCKS == 1) {
    fetch = require("./tests/__mocks__/apiMocks").fetch;
}

const initStore = {
    region: AWS_REGIONS[0],
    account: {
        name: "",
        website: "",
        users: [{ firstName: "", lastName: "", email: "", job: "" }],
        industry: INDUSTRIES[1],
        agentCount: AGENT_COUNT_RANGES[1]
    },
    amznMarketplaceToken: undefined,
    fetchState: { busy: false, error: undefined, done: false },
    existingAccount: undefined
};
const store = createStore(initStore);

export default () => (
    <Provider store={store}>
        <App />
    </Provider>
);
