export const AWS_REGIONS = [
    { label: "US East (N. Virginia) us-east-1", id: "us-east-1" },
    { label: "Asia Pacific (Sydney) ap-southeast-2", id: "ap-southeast-2" },
    { label: "Europe (London) eu-west-2", id: "eu-west-2" }
];

export const INDUSTRIES = [
    { id: "hotels_and_resorts", label: "Hotels & Resorts" },
    { id: "restaurants_and_bars", label: "Restaurants & Bars" },
    { id: "theme_parks", label: "Theme Parks" },
    { id: "stadiums_and_sports", label: "Stadiums & Sports" },
    { id: "attractions_venues_and_pois", label: "Attractions, Venues, POIs" },
    { id: "airport", label: "Airport" },
    { id: "airline", label: "Airline" },
    {
        id: "real_estate_and_asset_management",
        label: "Real Estate & Asset Management"
    },
    {
        id: "direct_marketing_organisation",
        label: "Direct Marketing Organisation (DMO)"
    },
    { id: "partner", label: "Partner" },
    { id: "agency", label: "Agency" },
    { id: "media_and_news", label: "Media & News" },
    { id: "retail_outlet", label: "Retail Outlet (Not F&B)" },
    { id: "shopping_centre", label: "Shopping Centre" },
    { id: "live_events_and_ticketing", label: "Live Events & Ticketing" },
    { id: "not_applicable", label: "N/A" }
];

export const AGENT_COUNT_RANGES = [
    { id: "< 100", label: "< 100" },
    { id: "100 - 500", label: "100 - 500" },
    { id: "500 - 1000", label: "500 - 1000" },
    { id: "1000 - 3000", label: "1000 - 3000" },
    { id: "> 3000", label: "> 3000" }
];

export const NUMBER_NAMES = [
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "TEN"
];
